(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        'lightbox': {
            init: function () {
                $('img[class*=wp-image-]').each(function () {
                    ($(this).parent().prop('tagName') !== 'FIGURE') && $(this).wrap('<figure class="wp-image"></figure>');
                });

                $(document).on('click', 'figure.wp-caption, figure.wp-image', function (e) {
                    var img, title, dlg;
                    img = $(this).prop('tagName') === 'IMG' ? $(this) : $(this).find('img');
                    title = img.attr('title') ? img.attr('title') : img.attr('alt');
                    title = (title.length > 0) ? title : img.attr('src').split("/").pop();
                    if (img.prop('tagName') === 'IMG' && bootbox) {
                        dlg = bootbox.dialog({
                            title: title,
                            backdrop: true,
                            className: 'bootbox-img',
                            onEscape: true,
                            message: $('<img/>', {
                                src: img.attr('src'),
                                srcset: img.attr('srcset'),
                                sizes: img.attr('sizes')
                            })
                        });
                    }
                })
            }
        },

        'product-video': {
            init: function () {
                $(document).on('click', '.btn-productvideo', function (e) {
                    e.preventDefault();
                    bootbox.dialog({
                        title: 'Product Video',
                        className: 'dialog-instruction',
                        message: '<iframe width="640" height="360" src="https://www.youtube.com/embed/nvxJnWTctUY" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>',
                        backdrop: true,
                        closeButton: true
                    });
                })
            }
        },

        'social': {
            init: function () {
                // JavaScript to be fired on the home page
                $(document).on('click', '.social a', function (e) {
                    e.preventDefault();
                    var a = $(this);
                    var i,
                        t = 575,
                        i = 440,
                        r = $(window).width() - (t / 2),
                        u = ($(window).height() - i) / 2,
                        n = "status=1,width=" + t + ",height=" + i + ",top=" + u + ",left=" + r;

                    !$(this).hasClass('popup') ? !0 : (e.preventDefault(), i = $(this).attr("href"), i || (i = location.href), window.open(i, "sharer", n))
                });
            }
        },


        // All pages
        'common': {
            init: function () {
                // Set data-useragent on html element
                var doc = document.documentElement;
                doc.setAttribute('data-useragent', navigator.userAgent);

                // Disable zoom on touch devices
                document.documentElement.addEventListener('touchstart', function (event) {
                    if (event.touches.length > 1) {
                        event.preventDefault();
                    }
                }, false);

                // Disable zoom by double tapping on touch enabled devices
                var lastTouchEnd = 0;
                document.documentElement.addEventListener('touchend', function (event) {
                    var now = (new Date()).getTime();
                    if (now - lastTouchEnd <= 300) {
                        event.preventDefault();
                    }
                    lastTouchEnd = now;
                }, false);
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },

        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
            UTIL.fire('lightbox', 'init');
            UTIL.fire('social', 'init');
            UTIL.fire('product-video', 'init');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
